import React, { useContext } from "react";

const TYPE_WEIGHTS = {
  bold: 800,
  semiBold: 700,
  regular: 600,
  semiLight: 500,
  light: 400,
};

const COLORS = {
  blue: "#cbd9ea",
  red: "#f25b44",
};

export type Theme = {
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
  type: {
    bold: number;
    semiBold: number;
    regular: number;
    semiLight: number;
    light: number;
  };
};

type ThemeContextType = {
  theme: Theme;
};

const ThemeContext = React.createContext<ThemeContextType>({
  theme: {
    colors: {
      primaryColor: COLORS.blue,
      secondaryColor: COLORS.red,
    },
    type: {
      bold: TYPE_WEIGHTS.bold,
      semiBold: TYPE_WEIGHTS.semiBold,
      regular: TYPE_WEIGHTS.regular,
      semiLight: TYPE_WEIGHTS.semiLight,
      light: TYPE_WEIGHTS.light,
    },
  },
});

export const ThemeProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <ThemeContext.Provider
      value={{
        theme: {
          colors: {
            primaryColor: COLORS.blue,
            secondaryColor: COLORS.red,
          },
          type: {
            bold: TYPE_WEIGHTS.bold,
            semiBold: TYPE_WEIGHTS.semiBold,
            regular: TYPE_WEIGHTS.regular,
            semiLight: TYPE_WEIGHTS.semiLight,
            light: TYPE_WEIGHTS.light,
          },
        },
      }}
      {...props}
    />
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
