import {
  CSSProperties,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
} from "react";
import styles from "./TextInput.module.scss";
import LoadingSpinner from "../LoadingSpinner";

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  containerStyle?: CSSProperties;
  style?: CSSProperties;
  loading?: boolean;
}

const TextInput = ({
  value,
  onChange,
  className,
  style,
  containerStyle,
  placeholder,
  onPaste,
  loading,
}: Props): JSX.Element => {
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const indent = "  ";
      const start = e.currentTarget.selectionStart;
      const end = e.currentTarget.selectionEnd;

      e.currentTarget.value =
        value.substring(0, start) + indent + value.substring(end, value.length);

      e.currentTarget.setSelectionRange(
        start + indent.length,
        start + indent.length
      );

      onChange({ target: e.currentTarget } as ChangeEvent<HTMLTextAreaElement>);
    }
  };

  return (
    <div className={styles.container} style={containerStyle || {}}>
      <textarea
        style={style}
        value={value}
        className={styles.textInput + " " + className}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        onPaste={onPaste}
      />
      <div
        style={{ display: loading ? "block" : "none" }}
        className={styles.overlay}
      >
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default TextInput;
