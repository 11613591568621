import { Col } from "react-flexbox-grid";
import Spacer from "../Spacer";
import styles from "./NamedForm.module.scss";
import Heading from "../Heading";

type Fields = JSX.Element | string | null;

interface Props {
  text: string;
  onSubmit: (formData: any) => void;
  children: JSX.Element | Fields[];
  id?: string;
}

const NamedForm = (props: Props) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.onSubmit(e);
  };
  return (
    <Col xs className={styles.formContainer}>
      <form id={props.id} onSubmit={handleSubmit}>
        <Heading
          text={props.text}
          type="l"
          style={{ alignSelf: "flex-start" }}
        />
        <Spacer withLine width="100%" justify="left" size="2em" />
        {props.children}
      </form>
    </Col>
  );
};

export default NamedForm;
