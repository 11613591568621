import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LabeledInput from "../components/LabeledInput";
import Button from "../components/Button";
import { setNewPassword } from "../api";
import { toast } from "react-toastify";
import NamedForm from "../components/NamedForm/NamedForm";
import { Col, Row } from "react-flexbox-grid";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";

interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

interface ResetPasswordErrors {
  password?: string;
  confirmPassword?: string;
}

const PasswordReset = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>("");
  const [errors, setErrors] = useState<ResetPasswordErrors>({});
  const [formData, setFormData] = useState<ResetPasswordFormData>({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const tokenParam = searchParams.get("token");

    if (!tokenParam) {
      return navigate("/");
    }
    setToken(tokenParam);
  }, [navigate, searchParams, token]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validate = (formData: ResetPasswordFormData): ResetPasswordErrors => {
    let errors: ResetPasswordErrors = {};
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Submit form data to server
      const { status } = await setNewPassword(formData.password, token);
      if (status === 200) {
        toast("Password updated!");
        navigate("/login");
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <GridWrapper>
      <StandoutCard>
        <Row center="xs">
          <Col md={6} xs={12}>
            <NamedForm text="Change Password" onSubmit={handleSubmit}>
              <LabeledInput
                text="New Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={errors.password}
              />
              <LabeledInput
                text="Confirm Password"
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={errors.confirmPassword}
              />
              <Button type="submit" text="Submit" onClick={() => {}} />
            </NamedForm>
          </Col>
        </Row>
      </StandoutCard>
    </GridWrapper>
  );
};

export default PasswordReset;
