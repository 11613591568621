import { createContext, useContext, useEffect, useState } from "react";
import { MOBILE_MAX_WIDTH } from "../global";

type ResponsiveContextType = {
  isMobile: boolean;
};

const ResponsiveContext = createContext<ResponsiveContextType>({
  isMobile: false,
});

export const ResponsiveProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ResponsiveContext.Provider
      value={{
        isMobile,
      }}
      {...props}
    />
  );
};

export const useResponsive = () => {
  return useContext(ResponsiveContext);
};
