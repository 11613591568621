import { useEffect, useState } from "react";
import { Stack } from "../types";
import { createStack, getAllStacks, updateStack } from "../api";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import styles from "./Stacks.module.scss";
import { useAuth } from "../contexts/AuthContext";
import NamedForm from "../components/NamedForm/NamedForm";
import LabeledInput from "../components/LabeledInput";
import Card from "../components/Card";
import PageHeading from "../components/PageHeading";
import { Col, Row } from "react-flexbox-grid";
import GridWrapper from "../components/GridWrapper";
import CustomGrid from "../components/CustomGrid";
import StandoutCard from "../components/StandoutCard";
import Spacer from "../components/Spacer";
import LoadingSpinner from "../components/LoadingSpinner";

Modal.setAppElement("#root");

const NameModal = ({
  isOpen,
  onClose,
  onSave,
  stack,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: number | undefined, name: string) => void;
  stack?: Stack;
}) => {
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (stack) {
      setName(stack.name);
    }
  }, [stack]);

  const handleSave = (_e: React.FormEvent<HTMLFormElement>) => {
    onSave(stack?.id, name);
    setName("");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={styles.modalContainer}
        overlayClassName={`${isOpen ? styles.fadeIn : ""}`}
      >
        <Col xs className={styles.modalContent}>
          <Row className={styles.formRow}>
            <NamedForm
              id="new-stack-form"
              text={stack ? "Edit Stack" : "New Stack"}
              onSubmit={handleSave}
            >
              <LabeledInput
                text="Name"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </NamedForm>
          </Row>
          <Row end="xs" className={styles.actionsContainer}>
            <Col xs className={styles.actionContainer}>
              <button type="button" className={styles.action} onClick={onClose}>
                Cancel
              </button>
            </Col>
            <Col xs className={styles.actionContainer}>
              <button
                form="new-stack-form"
                type="submit"
                className={styles.action}
              >
                Confirm
              </button>
            </Col>
          </Row>
        </Col>
        {isOpen && <div className={styles.backgroundOverlay}></div>}
      </Modal>
    </>
  );
};

const AddStackCard = (): JSX.Element => {
  return (
    <StandoutCard
      style={{
        backgroundColor: "#eee",
      }}
    >
      <div>x</div>
    </StandoutCard>
  );
};

const Stacks = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [stacks, setStacks] = useState<Stack[]>([]);
  const [stackIdx, setStackIdx] = useState<number | null>(null);
  const navigate = useNavigate();
  const { logout, isVerified } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await getAllStacks();
        if (status >= 200 && status < 400) {
          setStacks(data);
          setLoading(false);
        } else {
          logout();
        }
      } catch (e) {
        logout();
      }
    })();
  }, [logout]);

  if (!isVerified) {
    return (
      <GridWrapper>
        <StandoutCard
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Row>
            <Col>
              <h1>📣 Check your email</h1>
              <Spacer withLine size="3em" lineColor="black" />
              <p>
                You must verify your email before you can access your Pohtia
                account!
              </p>
            </Col>
          </Row>
        </StandoutCard>
      </GridWrapper>
    );
  }

  return (
    <>
      <GridWrapper
        className={`${styles.pageContainer} ${showModal ? styles.blur : ""}`}
      >
        <PageHeading
          text="Your Card Stacks"
          actions={[
            {
              text: "+ Add a stack",
              onClick: () => setShowModal(true),
            },
          ]}
        />
      </GridWrapper>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <CustomGrid>
          {stacks.length > 0 ? (
            stacks.map((s, idx) => (
              <Card
                key={s.id}
                sm={12}
                md={6}
                body={
                  <Card.Title
                    title={s.name}
                    subtitle={`${s.number_of_questions || 0} question(s)`}
                    onClick={() => {
                      setStackIdx(idx);
                      setShowModal(true);
                    }}
                  />
                }
                actions={[
                  {
                    text: "View",
                    onClick: () => {
                      navigate(`/stacks/${s.id}`);
                    },
                  },
                  {
                    text: "Study",
                    disabled: s.number_of_questions === 0,
                    onClick: () => {
                      navigate(`/stacks/${s.id}/study`);
                    },
                  },
                ]}
              />
            ))
          ) : (
            <div className={styles.centered}>Add some stacks!</div>
          )}
        </CustomGrid>
      )}

      <br />

      <NameModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        stack={stackIdx !== null ? stacks[stackIdx] : undefined}
        onSave={async (stackId: number | undefined, stackName: string) => {
          if (stackId) {
            const { status, data } = await updateStack(
              String(stackId),
              stackName
            );

            if (status === 200) {
              const newStacks = stacks.map((s) => {
                if (s.id === stackId) {
                  return data;
                }

                return s;
              });
              setStacks(newStacks);
              setShowModal(false);
            }
          } else {
            const { status, data } = await createStack(stackName);

            if (!data.number_of_questions) {
              data.number_of_questions = 0;
            }

            if (status === 200) {
              setStacks([...stacks, data]);
              setShowModal(false);
            }
          }
        }}
      />
    </>
  );
};

export default Stacks;
