import { useEffect, useRef, useState } from "react";
import styles from "./HamburgerMenu.module.scss";
import Spacer from "../Spacer";

type ChildType = JSX.Element | boolean;

interface Props {
  children: JSX.Element | ChildType[];
}

const HamburgerMenu = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className={styles.hamburgerMenu}>
      <div
        className={`${styles.hamburger} ${isOpen ? styles.open : ""}`}
        onClick={toggleMenu}
      >
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
      {isOpen && <div className={styles.menu}>{props.children}</div>}
    </div>
  );
};

export default HamburgerMenu;
