import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./NavBar.module.scss";
import { useResponsive } from "../../contexts/ResponsiveContext";
import HamburgerMenu from "../HamburgerMenu";
import styled from "styled-components";
import TextButton from "../TextButton";

const StyledNavBarRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-basis: 20vw;
`;

const NavBarRight = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <StyledNavBarRight>
      <TextButton
        text="quick start."
        onClick={() => navigate("/quick-start")}
      />

      {!isAuthenticated && (
        <TextButton text="sign up." onClick={() => navigate("/sign-up")} />
      )}
      {isAuthenticated && (
        <TextButton
          text="my account."
          onClick={() => navigate("/my-account")}
        />
      )}
      {isAuthenticated ? (
        <TextButton
          text="logout."
          onClick={async () => {
            await logout();
            navigate("/");
          }}
        />
      ) : (
        <TextButton text="login." onClick={() => navigate("/login")} />
      )}
    </StyledNavBarRight>
  );
};

const MobileMenu = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <HamburgerMenu>
      <TextButton text="home" onClick={() => navigate("/")} btnStyle="light" />
      <TextButton
        text="quick start"
        onClick={() => navigate("/quick-start")}
        btnStyle="light"
      />

      {!isAuthenticated && (
        <TextButton
          text="sign up"
          onClick={() => navigate("/sign-up")}
          btnStyle="light"
        />
      )}
      {isAuthenticated && (
        <TextButton
          text="my account"
          onClick={() => "/my-account"}
          btnStyle="light"
        />
      )}
      {isAuthenticated ? (
        <TextButton
          text="logout"
          onClick={async () => {
            await logout();
            navigate("/");
          }}
          btnStyle="light"
        />
      ) : (
        <TextButton
          text="login"
          onClick={() => navigate("/login")}
          btnStyle="light"
        />
      )}
    </HamburgerMenu>
  );
};

function NavBar() {
  const { isMobile } = useResponsive();

  return (
    <nav className={styles.navRoot}>
      <div className={styles.navbarContainer}>
        <div className={styles.navbarLeft}>
          <a href="/" className={styles.logo}>
            pohtia.
          </a>
        </div>
        {isMobile ? <MobileMenu /> : <NavBarRight />}
      </div>
    </nav>
  );
}

export default NavBar;
