import styles from "./LabeledValue.module.scss";

interface Props {
  text: string;
  value: string | number;
}

const LabeledValue = (props: Props) => {
  return (
    <div className={styles.container}>
      <label>
        <span className={styles.labelText}>{props.text}</span>
        {props.value}
      </label>
    </div>
  );
};

export default LabeledValue;
