import { ColProps, Col } from "react-flexbox-grid";
import styles from "./StandoutCard.module.scss";
import { CSSProperties } from "react";

interface Props extends ColProps {
  children: any;
  padding?: string;
  className?: string;
  styles?: CSSProperties;
}

const StandoutCard = ({
  children,
  className,
  style,
  ...rest
}: Props): JSX.Element => {
  return (
    <Col
      className={styles.standoutCard + " " + (className || "")}
      style={style}
      {...rest}
    >
      {children}
    </Col>
  );
};

export default StandoutCard;
