import {
  Navigate,
  Outlet,
  Route,
  RouteProps,
  Routes,
  useSearchParams,
} from "react-router-dom";
import styles from "./App.module.scss";
import { useAuth } from "./contexts/AuthContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/Signup";
import Stacks from "./pages/Stacks";
import Stack from "./pages/Stack";
import QuestionEditor from "./pages/QuestionEditor";
import NavBar from "./components/NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Study from "./pages/Study";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import MyAccount from "./pages/MyAccount";
import QuickStart from "./pages/QuickStart";

function Layout() {
  const [searchParams] = useSearchParams();

  if (searchParams.get("email_verification_success")) {
    toast("Email verified!");
  }
  return (
    <div id="layout">
      <Outlet />
      <ToastContainer
        progressClassName={styles.toastProgress}
        autoClose={2500}
      />
    </div>
  );
}

const PrivateLayout: React.FC<RouteProps> = () => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    logout();
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <NavBar />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={isAuthenticated ? <Stacks /> : <Home />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="quick-start" element={<QuickStart />} />

          {/* PRIVATE ROUTES */}
          <Route element={<PrivateLayout />}>
            <Route path="verify/email/confirmed" element={<VerifyEmail />} />
            <Route path="stacks/:stackId" element={<Stack />} />
            <Route path="stacks/:stackId" element={<Stack />} />
            <Route path="stacks/:stackId/study" element={<Study />} />
            <Route path="my-account" element={<MyAccount />} />
            <Route
              path="stacks/:stackId/questions/new"
              element={<QuestionEditor />}
            />
            <Route
              path="/stacks/:stackId/questions/:questionId"
              element={<QuestionEditor />}
            />
          </Route>
          <Route path="*" element={<div>404</div>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
