import styles from "./Callout.module.scss";

interface Props {
  text: string;
  type?: "bold" | "error";
}

const Callout = ({ text, type }: Props): JSX.Element => {
  return (
    <span className={styles.callout + " " + (type && styles[type])}>
      {text}
    </span>
  );
};

export default Callout;
