import styled from "styled-components";
import { Theme, useTheme } from "../contexts/ThemeContext";

type BtnStyle = "dark" | "light";

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  color?: "red" | "dark-grey" | "black" | "green";
  type?: "button" | "submit" | "reset";
  btnStyle?: BtnStyle;
  style?: {};
  icon?: string;
}

const calcColor = (btnStyle: BtnStyle): string => {
  switch (btnStyle) {
    case "dark":
      return "white";
    case "light":
      return "black";
  }
};

const calcBackgroundColor = (btnStyle: BtnStyle): string => {
  switch (btnStyle) {
    case "dark":
      return "black";
    case "light":
      return "white";
  }
};

interface StyleProps {
  theme: Theme;
  btnStyle: BtnStyle;
}

const StyledButton = styled.button<StyleProps>`
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid black;
  color: ${(props) => calcColor(props.btnStyle)};
  background-color: ${(props) => calcBackgroundColor(props.btnStyle)};
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
    color 0.2s ease-in-out;
  font-weight: ${(props) => props.theme.type.regular};
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${(props) => calcColor(props.btnStyle)};
    color: ${(props) => calcBackgroundColor(props.btnStyle)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type = "button",
  disabled,
  btnStyle = "dark",
}) => {
  const { theme } = useTheme();

  return (
    <StyledButton
      theme={theme}
      btnStyle={btnStyle}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
