import { useEffect, useState } from "react";
import { deleteAccount, getMe } from "../api";
import { User } from "../types";
import LabeledValue from "../components/LabeledValue";
import Spacer from "../components/Spacer";
import Button from "../components/Button";
import PageHeading from "../components/PageHeading";
import { useAuth } from "../contexts/AuthContext";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";
import LoadingSpinner from "../components/LoadingSpinner";

const calcLevel = (xp: number): number => {
  if (xp < 100) {
    return 1;
  }

  return Math.floor(xp / 100);
};

const MyAccount = () => {
  const [user, setUser] = useState<User>();
  const { logout } = useAuth();

  useEffect(() => {
    (async () => {
      const { status, data } = await getMe();

      if (status === 200) {
        setUser(data);
      }
    })();
  }, []);

  const handleAccountDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete you account?");

    if (!confirmed || !user) return;

    const { status } = await deleteAccount(String(user.id));

    if (status === 200) {
      logout();
    }
  };

  if (!user) return <LoadingSpinner />;

  return (
    <GridWrapper>
      <PageHeading text={"My Account"} actions={[]} />
      <StandoutCard style={{ flexDirection: "column" }}>
        <LabeledValue text="Level" value={calcLevel(user.questions_answered)} />
        <LabeledValue text="XP" value={user.questions_answered} />
        <Spacer withLine size="1em" lineColor="black" />
        <LabeledValue text="Email" value={user.email} />
        <Spacer size="2em" />
        <Button text="Delete account" onClick={handleAccountDelete} />
      </StandoutCard>
    </GridWrapper>
  );
};

export default MyAccount;
