import styles from "./QuickStart.module.scss";
import Spacer from "../components/Spacer";
import { Col, Row } from "react-flexbox-grid";
import StandoutCard from "../components/StandoutCard";
import GridWrapper from "../components/GridWrapper";
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";
import Callout from "../components/Callout/Callout";

const markdown = `Inline LaTeX example: $\\frac{1}{2}$

---
Block LaTex example:
$$
\\frac{\\partial u}{\\partial t} = h^2 \\left( \\frac{\\partial^2 u}{\\partial x^2} + \\frac{\\partial^2 u}{\\partial y^2} + \\frac{\\partial^2 u}{\\partial z^2} \\right)
$$
---
Inline code example: \`a + b = c\`

---
Code block example:
\`\`\`py
def my_func():
  a**2 + b**2 = c**2
\`\`\``;

// const Callout = ({ children, logo }: { children: string; logo?: boolean }) => (
//   <span className={logo ? styles.calloutLogo : styles.callout}>{children}</span>
// );

const QuickStart = () => {
  return (
    <GridWrapper>
      <Row>
        <StandoutCard style={{ flexDirection: "column" }} xs={6}>
          <Row>
            <Heading text="Quick start" />
          </Row>
          <Row>
            <Paragraph>
              Welcome to <Callout text="pohtia" />.
              <br />
              <br />
              This guide will help you get started with using the app's two main
              concepts: cards and stacks.
            </Paragraph>
          </Row>
        </StandoutCard>
        <Col xs={6} className={styles.deskImage} />
      </Row>
      <Spacer size="0.5em" />
      <Row>
        <Col xs={6} className={styles.clutterImage} />
        <StandoutCard
          xs={6}
          style={{ flexDirection: "column" }}
          className={styles.section}
        >
          <Row>
            <Heading text="Sign up and log in" />
          </Row>
          <Row>
            <Paragraph>
              The first thing you'll need to do is{" "}
              <Callout text="create an account" />,{" "}
              <Callout text="verify your email" />, and{" "}
              <Callout text="log in" />.
            </Paragraph>
          </Row>
        </StandoutCard>
      </Row>
      <Spacer size="0.5em" />
      <Row>
        <StandoutCard
          xs={6}
          className={styles.section}
          style={{ flexDirection: "column" }}
        >
          <Row>
            <Heading text="Create a stack" />
          </Row>
          <Row>
            <Paragraph>
              After logging in, you'll land on the dashboard.
            </Paragraph>

            <Paragraph>
              You'll have a default stack called "My stack" but can create a new
              one by clicking on the "Add a stack" button to making your own.
              Give your stack a name that reflects the subject or topic you want
              to study.
            </Paragraph>

            <Paragraph>
              Save the stack and it will appear in your main stack list.
            </Paragraph>
          </Row>
        </StandoutCard>
        <Col xs={6} className={styles.manDeskImage} />
      </Row>
      <Spacer size="0.5em" />

      <Row>
        <StandoutCard
          xs={12}
          className={styles.section}
          style={{ flexDirection: "column" }}
        >
          <Row>
            <Heading text="Add cards to a stack" />
          </Row>
          <Col>
            <Paragraph>Click on "view" to open a stack.</Paragraph>
            <Paragraph>
              Inside the stack, click on the "Add Card" button. Fill in the
              question and answer fields for the card - these fields support
              markdown with LaTeX and code blocks.
            </Paragraph>
            <Paragraph>All Markdown syntax is supported.</Paragraph>
            <Row>
              <Col xs={12} md={6}>
                <div>
                  <Paragraph>
                    To write inline LaTeX math notation place your markup
                    between two dollar signs:{" "}
                    <span className={styles.inlineFormat}>$</span>
                  </Paragraph>
                </div>
                <div>
                  <Paragraph>
                    To write a LaTeX math notation block place your markup
                    between two double dollar signs:{" "}
                    <span className={styles.inlineFormat}>$$</span>
                  </Paragraph>
                </div>
                <div>
                  <Paragraph>
                    To write inline code place your markup between two
                    backticks:
                    <span className={styles.inlineFormat}>`</span>
                  </Paragraph>
                </div>
                <div>
                  <Paragraph>
                    To write a code block place your markup between three
                    backticks: <span className={styles.inlineFormat}>```</span>
                  </Paragraph>
                </div>

                <p>
                  You can add as many cards as you want to the stack by
                  repeating this process.
                </p>
              </Col>
              <Col xs={12} md={6}>
                <div className={styles.dummyMarkdown}>
                  <Paragraph>
                    Inline LaTeX example: {`$\\frac{1}{2}$`}
                  </Paragraph>
                  <Paragraph>---</Paragraph>
                  <Paragraph>Block LaTex example:</Paragraph>
                  <Paragraph>{`$$`}</Paragraph>
                  <Paragraph>{`\\frac{\\partial u}{\\partial t} = h^2 \\left( \\frac{\\partial^2 u}{\\partial x^2} + \\frac{\\partial^2 u}{\\partial y^2} + \\frac{\\partial^2 u}{\\partial z^2} \\right)`}</Paragraph>
                  <Paragraph>{`$$`}</Paragraph>
                  <Paragraph>---</Paragraph>
                  <Paragraph>Inline code example: {`\`a + b = c\``}</Paragraph>
                  <Paragraph>---</Paragraph>
                  <Paragraph>Code block example:</Paragraph>
                  <Paragraph>{"```py"}</Paragraph>
                  <Paragraph>{"def my_func():"}</Paragraph>
                  <Paragraph className={styles.indent}>
                    {"  a**2 + b**2 = c**2"}
                  </Paragraph>
                  <Paragraph>{"```"}</Paragraph>
                </div>
              </Col>
            </Row>
          </Col>
        </StandoutCard>
      </Row>
      <Spacer size="0.5em" />

      <Row>
        <Col xs={6} className={styles.womanBookImage} />

        <StandoutCard
          xs={6}
          style={{ flexDirection: "column" }}
          className={styles.section}
        >
          <Row>
            <Heading text="Study" />
          </Row>
          <Row>
            <Paragraph>
              Once you've added your cards to your stack you can study them.
            </Paragraph>
            <Paragraph>
              Clicking the <span className={styles.callout}>study</span> button
              from the list view or the stack page will randomly present your
              cards to you one at time.
            </Paragraph>
            <Paragraph>
              You will see the question from your card. Take the time to write
              down the answer, answer out loud or in your head.
            </Paragraph>
            <Paragraph>
              <Callout text="Repeat this as often as you can!" /> The more often
              and more casually you study, the better you long term memory
              retention.
            </Paragraph>
          </Row>
        </StandoutCard>
      </Row>
    </GridWrapper>
  );
};

export default QuickStart;
