import { useState } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledInput = styled.input`
  display: flex;
  flex: 1 1;
  border: none;
  margin-bottom: 1em;
  &:focus {
    outline: none;
  }
`;

interface Props {
  placeHolder?: string;
  onChange?: (state: string) => void;
}

const Input = ({ onChange, placeHolder }: Props) => {
  const [text, setText] = useState<string>("");

  return (
    <StyledWrapper>
      <StyledInput
        placeholder={placeHolder || ""}
        value={text}
        onChange={(e) => {
          const val = e.target.value;
          setText(val);
          onChange && onChange(val);
        }}
      />
    </StyledWrapper>
  );
};

export default Input;
