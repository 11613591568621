import React, { useState } from "react";
import { oauthSignUp, signUpUser } from "../api";
import { useNavigate } from "react-router-dom";
import { Button } from "../components";
import LabeledInput from "../components/LabeledInput";
import NamedForm from "../components/NamedForm/NamedForm";
import ReCAPTCHA from "react-google-recaptcha";
import { Row } from "react-flexbox-grid";
import { toast } from "react-toastify";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../contexts/AuthContext";
import GoogleLogo from "../google-icon-logo-svg-vector.svg";
import Spacer from "../components/Spacer";
import Callout from "../components/Callout/Callout";

interface SignUpFormData {
  email: string;
  password: string;
  confirmPassword: string;
  recaptchaToken: string;
}

interface SignUpErrors {
  email?: string;
  password?: string;
  confirmPassword?: string;
  other?: string;
}

const SignUp = (): JSX.Element => {
  const [formData, setFormData] = useState<SignUpFormData>({
    email: "",
    password: "",
    confirmPassword: "",
    recaptchaToken: "",
  });
  const [errors, setErrors] = useState<SignUpErrors>({});
  const [isHuman, setIsHuman] = useState<boolean>(false);
  const { setIsAuthenticated, setUserId, setIsVerified } = useAuth();

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setIsHuman(true);
      setFormData({ ...formData, recaptchaToken: token });
    }
  };

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Submit form data to server
      const { status, data } = await signUpUser(
        formData.email,
        formData.password,
        formData.recaptchaToken
      );

      if (status === 200) {
        navigate("/login");
        toast("Check your email!");
      } else {
        setErrors({ other: data.errors } as SignUpErrors);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validate = (formData: SignUpFormData): SignUpErrors => {
    let errors: SignUpErrors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      const { status, data } = await oauthSignUp(res.access_token);
      if (status === 200) {
        setIsAuthenticated(true);
        setUserId(String(data.user_id));
        setIsVerified(true);

        navigate("/");
      } else if (status === 409) {
        setErrors({ other: "A user with this email already exists." });
      }
    },
    onError: (error) => setErrors({ other: `Signup Failed: ${error}` }),
  });

  return (
    <GridWrapper>
      <Row center="xs">
        <StandoutCard lg={6} md={12}>
          <NamedForm text="Sign up" onSubmit={handleSubmit}>
            <Button
              icon={GoogleLogo}
              type="button"
              text="Sign up with Google"
              onClick={() => login()}
            />
            <Spacer withLine size="2em" lineColor="black" />

            <LabeledInput
              text="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
            />
            <LabeledInput
              text="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={errors.password}
            />
            <LabeledInput
              text="Confirm Password"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={errors.confirmPassword}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTHCA_SITE_KEY || ""}
              onChange={handleRecaptcha}
            />
            {errors.other ? <Callout text={errors.other} type="error" /> : null}
            <Button
              type="submit"
              text="Sign up"
              disabled={!isHuman}
              onClick={() => {}}
            />
          </NamedForm>
        </StandoutCard>
      </Row>
    </GridWrapper>
  );
};

export default SignUp;
