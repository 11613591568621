import styles from "./CustomGrid.module.scss";

interface Props {
  children: any;
}

const CustomGrid = ({ children }: Props): JSX.Element => {
  return (
    <div
      className={styles.gridContainer}
      style={{
        gridTemplateColumns: `repeat(${
          children.length < 3 ? children.length : 3
        }, 1fr)`,
      }}
    >
      {children}
    </div>
  );
};

export default CustomGrid;
