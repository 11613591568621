import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";

interface Props {
  text: string;
  to: string;
}

const StyledNavLink = styled(NavLink)<{ charLength: number }>`
  display: inline-flex;
  color: black;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.type.regular};
  min-width: ${(props) => props.charLength}ex; /* Set based on char length */

  span {
    transition: transform 0.1s ease, font-weight 0.1s ease;
    display: inline-block;
    width: inherit;
  }

  &:hover > span {
    font-style: italic;
    font-weight: ${(props) => props.theme.type.light};
  }
`;

const LinkButton = (props: Props) => {
  const { theme } = useTheme();

  return (
    <StyledNavLink
      theme={theme}
      to={props.to}
      charLength={props.text.split("").length}
    >
      <span>{props.text}</span>
    </StyledNavLink>
  );
};

export default LinkButton;
