import React, { useState, useEffect } from "react";
import { loginUser, logoutUser } from "../api";
import CryptoJS from "crypto-js";

const KEY = process.env.REACT_AUTH_SECRET_KEY || "";

const localStorageFetch = <T,>(key: string, fallback?: T) => {
  const encryptedKey = CryptoJS.SHA256(key).toString();

  if (localStorage.getItem(encryptedKey)) {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem(encryptedKey) as string,
      KEY
    );
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

    if (key === "userId") return decryptedValue;
    return JSON.parse(decryptedValue);
  }

  return fallback;
};

const localStorageSet = <T,>(key: string, value: T) => {
  const encryptedKey = CryptoJS.SHA256(key).toString();
  const encryptedValue = CryptoJS.AES.encrypt(String(value), KEY).toString();
  localStorage.setItem(encryptedKey, encryptedValue);
};

const localStorageDelete = <T,>(key: string) => {
  const encryptedKey = CryptoJS.SHA256(key).toString();
  localStorage.removeItem(encryptedKey);
};

type AuthContextType = {
  isAuthenticated: boolean;
  isVerified: boolean;
  setIsVerified: (val: boolean) => void;
  setIsAuthenticated: (val: boolean) => void;
  setUserId: (val: string) => void;
  userId: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<boolean>;
};

const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: localStorageFetch("isAuthenticated", false),
  isVerified: localStorageFetch("isVerified", false),
  userId: localStorageFetch("userId", null),
  login: async (_email: string, _password: string) => false,
  logout: async () => false,
  setIsVerified: (_val: boolean) => {},
  setIsAuthenticated: (_val: boolean) => {},
  setUserId: (_val: string) => {},
});

export const AuthProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorageFetch("isAuthenticated", false)
  );
  const [isVerified, setIsVerified] = useState<boolean>(
    localStorageFetch("isVerified", false)
  );
  const [userId, setUserId] = useState<string | null>(
    localStorageFetch("userId", null)
  );

  useEffect(() => {
    localStorageSet("isAuthenticated", isAuthenticated);
    localStorageSet("isVerified", isVerified);
    localStorageSet("userId", userId);
  }, [isAuthenticated, isVerified, userId]);

  const login = async (email: string, password: string): Promise<boolean> => {
    const { status, data } = await loginUser(email, password);

    if (status === 200) {
      setIsAuthenticated(true);
      setIsVerified(data.email_confirmed);
      setUserId(`${data.user_id}`);
      return true;
    }

    return false;
  };

  const logout = async () => {
    try {
      logoutUser();
    } catch (error) {
    } finally {
      localStorageDelete("isAuthenticated");
      localStorageDelete("isVerified");
      localStorageDelete("userId");

      setIsAuthenticated(false);
      setUserId(null);
      return true;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        isVerified,
        setIsVerified,
        setIsAuthenticated,
        setUserId,
        login,
        logout,
      }}
      {...props}
    />
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
