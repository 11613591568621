import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import LabeledInput from "../components/LabeledInput";
import { Button } from "../components";
import NamedForm from "../components/NamedForm/NamedForm";
import { Row } from "react-flexbox-grid";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";
import GoogleLogo from "../google-icon-logo-svg-vector.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { oauthlLoginUser } from "../api";
import Spacer from "../components/Spacer";
import { toast } from "react-toastify";
import Callout from "../components/Callout/Callout";

interface LoginFormData {
  email: string;
  password: string;
}

const Login = (): JSX.Element => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>("");

  const { login, setIsAuthenticated, setUserId, setIsVerified } = useAuth();
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    try {
      const success = await login(formData.email, formData.password);
      if (success) {
        navigate("/");
      } else {
        setError("Incorrect email or password.");
      }
    } catch (error) {
      toast("Error with login.", { type: "error" });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      const { status, data } = await oauthlLoginUser(res.access_token);
      if (status === 200) {
        setIsAuthenticated(true);
        setUserId(String(data.user_id));
        setIsVerified(true);

        navigate("/");
      } else {
        setError("Google login failed.");
      }
    },
    onError: (error) => setError(`Google login failed: ${error}`),
  });

  return (
    <GridWrapper>
      <Row center="xs">
        <StandoutCard md={12} lg={6}>
          <NamedForm text="Login" onSubmit={handleSubmit}>
            <Button
              icon={GoogleLogo}
              type="button"
              text="Login with Google"
              onClick={() => googleLogin()}
              btnStyle="light"
            />
            <Spacer withLine size="2em" lineColor="black" />
            <LabeledInput
              text="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <LabeledInput
              text="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {error && <Callout text={error} type="error" />}

            <Button type="submit" text="Login" onClick={() => {}} />
            <span className={styles.forgotBtn}>
              <Button
                type="submit"
                text="Forgot password"
                onClick={() => {
                  navigate("/forgot-password");
                }}
                btnStyle="light"
              />
            </span>
          </NamedForm>
        </StandoutCard>
      </Row>
    </GridWrapper>
  );
};

export default Login;
