import { Grid } from "react-flexbox-grid";
import styles from "./GridWrapper.module.scss";
import { CSSProperties } from "react";

interface Props {
  children: any;
  className?: string;
  style?: CSSProperties;
}

const GridWrapper = ({ children, className, style }: Props): JSX.Element => {
  return (
    <Grid fluid className={styles.grid + " " + className} style={style || {}}>
      {children}
    </Grid>
  );
};

export default GridWrapper;
