import { useState } from "react";
import LabeledInput from "../components/LabeledInput";
import styles from "./ForgotPassword.module.scss";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { resetPassword } from "../api";
import NamedForm from "../components/NamedForm/NamedForm";
import { Col, Row } from "react-flexbox-grid";
import { useNavigate } from "react-router-dom";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";

interface ForgotFormData {
  email: string;
}

const ForgotPassword = () => {
  const [formData, setFormData] = useState<ForgotFormData>({
    email: "",
  });

  const [error, setError] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    try {
      const { status } = await resetPassword(formData.email);
      if (status === 200) {
        toast("Check your email!");
        setSubmitted(true);
      }
    } catch (error) {
      setError("No account found for that email.");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  if (submitted) {
    navigate("/");
  }

  return (
    <GridWrapper>
      <Row center="xs">
        <StandoutCard lg={6} md={12}>
          <NamedForm text="Reset password" onSubmit={handleSubmit}>
            <LabeledInput
              text="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {error && <div className={styles.error}>{error}</div>}

            <Button type="submit" text="Reset" onClick={() => {}} />
          </NamedForm>
        </StandoutCard>
      </Row>
    </GridWrapper>
  );
};

export default ForgotPassword;
