import { Col, Row } from "react-flexbox-grid";
import styles from "./Home.module.scss";
import Spacer from "../components/Spacer";
import CustomMarkdown from "../components/CustomMarkdown";
import { useResponsive } from "../contexts/ResponsiveContext";
import CollapsibleSection from "../components/CollapsibleSection";
import { KeyboardEvent, useState } from "react";
import StandoutCard from "../components/StandoutCard";
import GridWrapper from "../components/GridWrapper";
import TextInput from "../components/TextInput";
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";
import Callout from "../components/Callout/Callout";

const latex = `$$
S(\\omega)=\\frac{\\alpha g^2}{\\omega^5} \\,
e ^{[-0.74\\bigl\\{\\frac{\\omega U_\\omega 19.5}{g}\\bigr\\}^{-4}]}
$$`;

const code = `
\`\`\`c
#include <stdio.h>
int main() {
  printf("Hello, World!");
  return 0;
}
\`\`\`
`;

const MathExample = ({ isMobile }: { isMobile: boolean }) => {
  const content = (
    <Row className={styles.latexRow} around="xs">
      <StandoutCard xs={10} md={6} className={styles.latexMarkdownExample}>
        <div>
          <Paragraph>$$</Paragraph>
          <Paragraph>{`S(\\omega)=\\frac{\\alParagraphha g^2}{\\omega^5} \\,`}</Paragraph>
          <Paragraph>
            {`// e ^{[-0.74\\bigl\\{\\frac{\\omega U_\\omega 19.5}{g}\\bigr\\}^{-4}]}`}
          </Paragraph>
          <Paragraph>$$</Paragraph>
        </div>
      </StandoutCard>

      <StandoutCard xs={10} md={6} className={styles.latexExample}>
        <CustomMarkdown value={latex} />
      </StandoutCard>
    </Row>
  );

  return content;
};

const CodeExample = ({ isMobile }: { isMobile: boolean }) => {
  const content = (
    <Row around="xs">
      <StandoutCard xs={10} md={6} className={styles.codeMarkdownExample}>
        <div>
          {code.split("\n").map((l, idx) => (
            <Paragraph className={[4, 5].includes(idx) ? styles.indent : ""}>
              {l}
            </Paragraph>
          ))}
        </div>
      </StandoutCard>

      <StandoutCard xs={10} md={6} className={styles.codeExample}>
        <CustomMarkdown value={code} />
      </StandoutCard>
    </Row>
  );

  return content;
};

const ImageExample = ({ isMobile }: { isMobile: boolean }) => {
  const content = (
    <Row around="xs">
      <StandoutCard xs={10} md={6} className={styles.imageMarkdownExample}>
        <div>
          <Paragraph>{`![](https://pohtia.com/pohtia_logo.png)`}</Paragraph>
        </div>
      </StandoutCard>
      <StandoutCard xs={10} md={6} className={styles.imageExample}>
        <CustomMarkdown value={"![](https://i.imgur.com/3NS5lLI.png)"} />
      </StandoutCard>
    </Row>
  );

  return content;
};

const TryItExample = ({ isMobile }: { isMobile: boolean }) => {
  const [raw, setRaw] = useState<string>(
    `## Change me!

#### Math example

$$
f(x) = \\frac{1}{2\\pi i} \\oint_{\\gamma} \\frac{e^z}{z^2 + 1} \\, dz
$$

---

#### Code example

\`\`\`py
from math import sqrt

def hypotenuse(a, b):
  c = sqrt(a**2 + b**2)
  return c
\`\`\``
  );

  return (
    <GridWrapper>
      <Row className={styles.tryItHeading}>
        <StandoutCard className={styles.tagContainer}>
          <Row>
            <Heading text="TRY IT" type="m" style={{ fontWeight: 600 }} />
          </Row>
          <Row>
            <span className={styles.subheading}> Edit the example below</span>
          </Row>
        </StandoutCard>
      </Row>
      <Row between="xs">
        <StandoutCard xs={12} md={6} className={styles.tryItCard}>
          <TextInput
            value={raw}
            onChange={(e) => setRaw(e.target.value)}
            containerStyle={isMobile ? { height: "10em" } : {}}
            style={{
              borderLeft: "1px dashed white",
              overflowY: "scroll",
              height: "85%",
            }}
          />
        </StandoutCard>
        <StandoutCard xs={12} md={6} className={styles.tryItExample}>
          <CustomMarkdown value={raw} />
        </StandoutCard>
      </Row>
    </GridWrapper>
  );
};

const HeroSection = ({ isMobile }: { isMobile?: boolean }): JSX.Element => {
  if (isMobile) {
    return (
      <GridWrapper className={styles.heroSection}>
        <Row className={styles.heroImage}></Row>
        <StandoutCard
          xs={12}
          style={{ flexDirection: "column" }}
          className={styles.card}
        >
          <Heading
            text="Flashcards with first class code, math, and Markdown support"
            type="m"
            style={{ fontWeight: 800 }}
          />
          <Paragraph>
            <Callout text="pohtia" /> is the flashcard app and study tool for{" "}
            <Callout text="STEM" />.
          </Paragraph>
          <Paragraph>
            Elevate your learning experience and knowledge retention with our
            standout features, including seamless support for{" "}
            <Callout text="code snippets" />, <Callout text="LaTeX" />,{" "}
            <Callout text="image hosting" /> and <Callout text="Markdown" />.
          </Paragraph>
        </StandoutCard>
      </GridWrapper>
    );
  }

  return (
    <GridWrapper className={styles.heroImage}>
      <Row>
        <StandoutCard md={7} lg={7} xl={6} style={{ flexDirection: "column" }}>
          <Heading
            text="Flashcards with first class code, math, and Markdown support"
            type="m"
            style={{
              fontWeight: 800,
            }}
          />
          <Spacer size="0.5em" />
          <Paragraph>
            <span className={styles.callout}>pohtia</span> is the flashcard app
            and study tool for <span className={styles.callout}>STEM</span>.
          </Paragraph>
          <Paragraph>
            Elevate your learning experience and knowledge retention with our
            standout features, including seamless support for{" "}
            <Callout text="code snippets" />, <Callout text="LaTeX" />,{" "}
            <Callout text="image hosting" /> and <Callout text="Markdown" />.
          </Paragraph>
        </StandoutCard>
      </Row>
    </GridWrapper>
  );
};

const Home = () => {
  const { isMobile } = useResponsive();

  return (
    <>
      <HeroSection isMobile={isMobile} />
      <TryItExample isMobile={isMobile} />
      {isMobile && <Spacer size="1em" />}
      <GridWrapper className={styles.howItWorksSection}>
        <Row>
          <StandoutCard
            xl={6}
            lg={7}
            md={7}
            xs={12}
            style={{ flexDirection: "column", marginBottom: "0.5em " }}
            className={styles.card}
          >
            <Heading text="How it works" />
            <Spacer size="1em" />
            <Row className={styles.tagLine}>
              <Col xs={4}>
                <Callout text="write." />
              </Col>
              <Col xs={4}>
                <Callout text="study." />
              </Col>
              <Col xs={4}>
                <Callout text="remember." />
              </Col>
            </Row>
          </StandoutCard>
        </Row>
        <Row>
          <StandoutCard
            xl={6}
            lg={7}
            md={7}
            xs={12}
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            className={styles.card}
          >
            <Heading text="Turn your notes into flashcards" type="s" />
            <Paragraph>
              Use the web interface to write flashcards with{" "}
              <Callout text="code" />, <Callout text="LaTeX" />,{" "}
              <Callout text="images" />, and <Callout text="markdown" /> quickly
              and easily.
            </Paragraph>
            <Spacer size="1em" lineColor="black" width="15%" justify="left" />

            <Heading text="Study anytime, anywhere" type="s" />
            <Paragraph>
              Gamify and test your knowledge with <Callout text="pohtia" />{" "}
              self-testing tools.
            </Paragraph>
            <Spacer size="1em" lineColor="black" width="15%" justify="left" />

            <Heading text="Long term retention" type="s" />
            <Paragraph>
              Consistent note taking and self-study is the simplest way to get
              complex information into your long term memory.
            </Paragraph>
          </StandoutCard>
        </Row>
      </GridWrapper>
      {isMobile && <Spacer size="1em" />}

      <GridWrapper className={styles.auxSection}>
        <Row className={styles.boxShadow}>
          <Col xs={5} sm={6} className={styles.auxSectionImage} />
          <StandoutCard
            style={{ flexDirection: "column", boxShadow: "none" }}
            xs={7}
            sm={6}
            className={styles.card}
          >
            <Heading text="Seamless math, code, and Markdown support" />
            <Spacer size="0.5em" />
            <Paragraph>
              Dive into the intricacies of math and coding with ease.{" "}
              <Callout text="pohtia" /> provides robust support for complex
              equations, formulas, and code snippets. Create, edit, and{" "}
              <Callout text="visualize mathematical expressions" /> and{" "}
              <Callout
                text="code
              blocks using Markdown"
              />
              .
            </Paragraph>
          </StandoutCard>
        </Row>
        {!isMobile && <Spacer size="0.5em" />}
        <MathExample isMobile={isMobile} />
        {!isMobile && <Spacer size="0.5em" />}

        <CodeExample isMobile={isMobile} />
        {!isMobile && <Spacer size="3em" />}

        <Row className={styles.boxShadow}>
          <StandoutCard
            xs={7}
            sm={6}
            style={{ flexDirection: "column", boxShadow: "none" }}
            className={styles.card}
          >
            <Heading text="Automatic image hosting" />
            <Spacer size="0.5em" />
            <Paragraph>
              <Callout text="Copy-pasted images are automatically hosted" /> and
              added to your flash cards. <Callout text="pohtia" /> detects
              images being added to your flash cards and renders them
              automatically.
            </Paragraph>
          </StandoutCard>
          <Col xs={5} sm={6} className={styles.imageSectionImage}></Col>
        </Row>
        {!isMobile && <Spacer size="0.5em" />}
        <ImageExample isMobile={isMobile} />
      </GridWrapper>
    </>
  );
};

export default Home;
