import { CSSProperties } from "react";
import styles from "./Heading.module.scss";

type HeadingType = "l" | "m" | "s" | "xs";

interface Props {
  text: string;
  type?: HeadingType;
  style?: CSSProperties;
}

const computeStyle = (type: HeadingType | undefined): CSSProperties => {
  const mediumStyle = {
    fontSize: "2.5em",
    fontWeight: 400,
  };

  switch (type) {
    case "l":
      return {
        fontSize: "5em",
        fontWeight: 300,
      };
    case "m":
      return mediumStyle;
    case "s":
      return {
        fontSize: "1.5em",
        fontWeight: 500,
      };
    case "xs":
      return {
        fontSize: "1em",
        fontWeight: 500,
      };
    default:
      return mediumStyle;
  }
};

const Heading = ({ text, type, style }: Props): JSX.Element => {
  let className;
  switch (type) {
    case "l":
      className = styles.large;
      break;
    case "m":
      className = styles.medium;
      break;
    case "s":
      className = styles.small;
      break;
    case "xs":
      className = styles.xsmall;
      break;
    default:
      className = styles.medium;
      break;
  }

  return (
    <div style={style || {}} className={className}>
      {text}
    </div>
  );
};

export default Heading;
