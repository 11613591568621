import { Col } from "react-flexbox-grid";
import styles from "./LabeledInput.module.scss";
import Callout from "../Callout/Callout";

interface Props {
  text: string;
  type: string;
  name: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

const LabeledInput = (props: Props) => {
  return (
    <Col xs className={styles.container}>
      <label>
        <span className={styles.labelText}>{props.text}</span>
        <input
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
      </label>
      {props.error && <Callout text={props.error} type="error" />}
    </Col>
  );
};

export default LabeledInput;
