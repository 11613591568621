import styles from "./Spacer.module.scss";

type SpaceType = "around" | "top" | "bottom";

interface Props {
  withLine?: boolean;
  lineColor?: "black" | "white";
  size?: string;
  width?: string;
  justify?: "left" | "center" | "right";
  space?: SpaceType;
}

const calcMargin = (space: SpaceType, size: string) => {
  switch (space) {
    case "top":
      return { marginTop: size };
    case "bottom":
      return { marginBottom: size };
    case "around":
    default:
      return { margin: `${size} 0px` };
  }
};

const Spacer = (props: Props): JSX.Element => {
  const margin = props.size && calcMargin(props.space || "around", props.size);

  return (
    <div
      className={`${styles.spacer} ${props.justify && styles[props.justify]}`}
      style={margin || {}}
    >
      <div
        className={`${props.withLine ? styles.line : ""}`}
        style={{
          width: props.width || "100%",
          borderColor: props.lineColor || "white",
        }}
      ></div>
    </div>
  );
};

export default Spacer;
