import { useEffect } from "react";
import { getMe } from "../api";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const { setIsVerified } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { status, data } = await getMe();

      if (status === 200 && data.email_confirmed) {
        setIsVerified(true);
        toast("Email confirmed!");
      }
    })();
  }, [setIsVerified]);

  setTimeout(() => navigate("/"), 2000);

  return <div>Thank you for confirming!</div>;
};

export default VerifyEmail;
