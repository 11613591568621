import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";

type BtnStyle = "dark" | "light";

interface Props {
  text: string;
  onClick: () => void;
  btnStyle?: BtnStyle;
}

const StyledBtn = styled.p<{ charLength: number; btnStyle: BtnStyle }>`
  padding: none;
  background: none;
  border: none;
  color: ${(props) => (props.btnStyle === "dark" ? "black" : "white")};
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.type.semiLight};
  min-width: ${(props) => props.charLength}ex;
  cursor: pointer;

  transition: transform 0.1s ease, font-weight 0.1s ease;

  &:hover {
    font-weight: ${(props) => props.theme.type.semiBold};
  }
`;

const TextButton = ({ text, onClick, btnStyle = "dark" }: Props) => {
  const { theme } = useTheme();

  return (
    <StyledBtn
      theme={theme}
      btnStyle={btnStyle}
      charLength={text.split("").length}
      onClick={onClick}
    >
      {text}
    </StyledBtn>
  );
};

export default TextButton;
