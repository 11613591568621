import { CSSProperties } from "react";
import styles from "./Paragraph.module.scss";

type ChildProp = JSX.Element | string;

interface Props {
  children: ChildProp[] | ChildProp;
  style?: CSSProperties;
  className?: string;
}

const Paragraph = ({ children, style, className }: Props): JSX.Element => {
  return (
    <p className={styles.paragraph + " " + className} style={style || {}}>
      {children}
    </p>
  );
};

export default Paragraph;
