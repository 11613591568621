import { Row, Col } from "react-flexbox-grid";
import styles from "./PageHeading.module.scss";

type Action = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

interface Props {
  text: string;
  actions?: Action[];
}

const PageHeading = (props: Props) => {
  return (
    <Col xs className={styles.heading}>
      <Row>
        <Col xs className={styles.headingText}>
          {props.text}
        </Col>
      </Row>
      {props.actions && (
        <Row between="xs" className={styles.actionContainer}>
          {props.actions.map((a, idx) => {
            return (
              <Col xs key={idx}>
                <span
                  className={`${styles.action} ${
                    a.disabled ? styles.disabled : ""
                  }`}
                  onClick={a.disabled ? () => {} : a.onClick}
                >
                  {a.text}
                </span>
              </Col>
            );
          })}
        </Row>
      )}
    </Col>
  );
};

export default PageHeading;
