import { useState } from "react";
import styles from "./QuestionCard.module.scss";
import Card from "../Card";
import CustomMarkdown from "../CustomMarkdown";
import { useNavigate } from "react-router-dom";
import { Col } from "react-flexbox-grid";

function QuestionCard({
  stackId,
  questionId,
  question,
  answer,
}: {
  stackId: string;
  questionId: number;
  question: string;
  answer: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <Col xs className={styles.container}>
      <Card
        className={styles.question}
        body={
          <Col xs className={isOpen ? styles.open : styles.closed}>
            <CustomMarkdown value={question} />
          </Col>
        }
        actions={[
          {
            text: isOpen ? "Collapse" : "Show",
            onClick: handleToggle,
          },
          {
            text: "Edit",
            onClick: () => {
              navigate(`/stacks/${stackId}/questions/${questionId}`);
            },
          },
        ]}
      />
      {isOpen && (
        <Card
          className={styles.answerContainer}
          body={
            <Col xs className={`${styles.answer} ${isOpen ? styles.show : ""}`}>
              <CustomMarkdown value={answer} />
            </Col>
          }
        />
      )}
    </Col>
  );
}

export default QuestionCard;
